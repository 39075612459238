import { ModelBase } from './ModelBase'

import { sdk } from 'Services/shelfNetworkSdk'
import get from 'lodash/get'

export class CarfaxReport extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    const { maker, model, year } = get(raw, 'relationships.record', {})

    this.id = this._str(raw.id)
    this.name = `${maker} ${model} ${year}`
    this.vin = this._str(raw.vin)
    this.phone = this._str(raw.phone)
    this.email = this._str(raw.email)
    this.fulfilledAt = this._date(raw.fulfilledAt)
    this.link = sdk.carfax.getDocumentUrl(
      get(raw, 'relationships.document.id', '')
    )
    this.dailyReportsCount = get(raw, 'relationships.dailyStats.count', 0)
  }
}
