export const actions = {
  LOAD_REPORTS: 'LOAD_REPORTS',
  LOAD_MORE_REPORTS: 'LOAD_MORE_REPORTS'
}

export const mutations = {
  SET_REPORTS: 'SET_REPORTS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_REPORTS_LOADING: 'SET_IS_NEXT_REPORTS_LOADING',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT'
}
