<template>
  <div class="carfax-list">
    <carfax-list-head />

    <div class="carfax-list__blocks">
      <div
        v-for="item in reportsMap"
        :key="item.date"
        class="carfax-list__date-block"
      >
        <div class="carfax-list__date-wrp">
          <p class="carfax-list__date">
            {{
              $t('DATE_BLOCK_TITLE', {
                date: item.date,
                count: $n(item.reportsCount)
              })
            }}
          </p>
        </div>

        <carfax-item
          class="carfax-list__item"
          v-for="report in item.reports"
          :key="report.id"
          :report="report"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CarfaxItem from './CarfaxItem'
import CarfaxListHead from './CarfaxListHead'

import uniq from 'lodash/uniq'
import get from 'lodash/get'
import { formatDateRelative } from 'Utils/dateHelpers'

export default {
  name: 'carfax-list',
  components: {
    CarfaxListHead,
    CarfaxItem
  },

  props: {
    reports: {
      type: Array,
      required: true
    }
  },

  computed: {
    reportsMap () {
      const reportDates = uniq(
        this.reports.map(r => new Date(r.fulfilledAt).toDateString())
      )

      return reportDates.map(dateStr => {
        const filteredReports = this.reports
          .filter(r => new Date(r.fulfilledAt).toDateString() === dateStr)

        return {
          date: formatDateRelative(new Date(dateStr)),
          reportsCount: get(filteredReports, '[0].dailyReportsCount', 0),
          reports: filteredReports
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carfax-list {
  &__date-wrp {
    padding: 2em 1em 1em;

    @include respond-below(sm) {
      padding: 0.6em 0;
    }
  }

  &__date-block {
    &:not(:first-child) {
      margin-top: 2.4em;
    }
  }

  &__date {
    font-weight: 500;
    font-size: 1.4em;
  }
}
</style>

<i18n>
{
  "en": {
    "DATE_BLOCK_TITLE": "{date} ({count})",
    "TODAY_DATE_FORMAT": "Today",
    "YESTERDAY_DATE_FORMAT": "Yesterday"
  },
  "ka": {
    "DATE_BLOCK_TITLE": "{date} ({count})",
    "TODAY_DATE_FORMAT": "დღეს",
    "YESTERDAY_DATE_FORMAT": "Გუშინ"
  },
  "ru": {
    "DATE_BLOCK_TITLE": "{date} ({count})",
    "TODAY_DATE_FORMAT": "Сегодня",
    "YESTERDAY_DATE_FORMAT": "Вчера"
  },
  "uk": {
    "DATE_BLOCK_TITLE": "{date} ({count})",
    "TODAY_DATE_FORMAT": "Сегодня",
    "YESTERDAY_DATE_FORMAT": "Вчера"
  }
}
</i18n>
