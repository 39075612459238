<template>
  <div class="carfax-promo-codes">
    <ui-button-v2
      class="carfax-promo-codes__btn"
      @click="isPromoCodesModalShown = true"
    >
      {{ $t('PROMO_CODES') }}
    </ui-button-v2>

    <ui-portal>
      <ui-modal
        mode-xs="drawerBottom"
        v-if="isPromoCodesModalShown"
        @close="close"
      >
        <h2 class="carfax-promo-codes__heading">
          {{ $t('PROMO_CODES') }}
        </h2>
        <div class="carfax-promo-codes__content">
          <div
            class="carfax-promo-codes__form"
            v-if="!generated.length"
          >
            <div class="carfax-promo-codes__opt">
              <div class="carfax-promo-codes__opt-calendar">
                <calendar
                  :value="codesExpirationDate"
                  @change="codesExpirationDate = $event[0]"
                />
                <div class="carfax-promo-codes__opt-calendar-presets">
                  <ui-button-v2
                    @click="setDefaultExpDate(7)"
                    class="carfax-promo-codes__opt-calendar-preset"
                  >
                    {{ $t('DEFAULT_EXP_7_DAYS') }}
                  </ui-button-v2>
                  <ui-button-v2
                    @click="setDefaultExpDate(14)"
                    class="carfax-promo-codes__opt-calendar-preset"
                  >
                    {{ $t('DEFAULT_EXP_14_DAYS') }}
                  </ui-button-v2>
                  <ui-button-v2
                    @click="setDefaultExpDate(30)"
                    class="carfax-promo-codes__opt-calendar-preset"
                  >
                    {{ $t('DEFAULT_EXP_30_DAYS') }}
                  </ui-button-v2>
                </div>
              </div>
            </div>
            <div class="carfax-promo-codes__opt">
              <div class="carfax-promo-codes__opt-amount">
                <strong class="carfax-promo-codes__opt-amount-val">
                  {{ codesAmount }}
                </strong>

                <ui-range
                  step="1"
                  min="1"
                  max="100"
                  v-model="codesAmount"
                />
                <p class="carfax-promo-codes__opt-amount-lbl">
                  {{ $t('AMOUNT_LBL') }}
                </p>
              </div>
              <div class="carfax-promo-codes__opt-expire">
                <p class="carfax-promo-codes__opt-expire-lbl">
                  {{ $t('EXPIRED_LBL') }}
                </p>
                <p class="carfax-promo-codes__opt-expire-date">
                  {{ formatDate(codesExpirationDate, 'yyyy-MM-dd HH:mm') }}
                </p>
              </div>
            </div>
          </div>

          <template v-if="generated.length">
            <div
              class="carfax-promo-codes__list"
              v-if="generated.length <= maxCodesToShow"
            >
              <ui-copy-button
                :value="item.id"
                :copied-message="$t('COMMON.COPIED_MSG')"
                v-for="item in generated"
                :key="item.id"
                class="carfax-promo-codes__list-item"
              >
                {{ item.id }}
              </ui-copy-button>
            </div>
            <div
              v-else
              class="carfax-promo-codes__downloaded-msg">
              {{ $t('PROMO_CODES_DOWNLOADED_MSG') }}
            </div>
          </template>
        </div>

        <div class="carfax-promo-codes__action">
          <template v-if="generated.length">
            <ui-button-v2
              @click="resetPromoCodes"
              class="carfax-promo-codes__action-btn
                carfax-promo-codes__action-btn_reset"
            >
              {{ $t('GENERATE_AGAIN_BTN') }}
            </ui-button-v2>

            <div
              class="carfax-promo-codes__action-group"
              v-if="generated.length > 1"
            >
              <ui-copy-button
                :value="copyCodesList"
                :copied-message="$t('COMMON.COPIED_MSG')"
                class="carfax-promo-codes__action-copy"
              >
                {{ $t('COPY_ALL_BTN') }}
              </ui-copy-button>
              <ui-button-v2
                @click="exportPromoCodes"
                class="carfax-promo-codes__action-download"
              >
                <ui-icon
                  shape="square"
                  icon="document"
                  class="carfax-promo-codes__action-download-cion"
                />
                {{ $t('EXPORT_CSV_BTN') }}
              </ui-button-v2>
            </div>
          </template>

          <ui-button-v2
            v-else
            @click="generatePromoCodes"
            :disabled="!isValidDate"
            class="carfax-promo-codes__action-btn"
          >
            {{ $t('GENERATE_BTN') }}
          </ui-button-v2>
        </div>
      </ui-modal>
    </ui-portal>
  </div>
</template>

<script>
import { sdk } from 'Services/shelfNetworkSdk'
import {
  UiButtonV2,
  UiModal,
  UiPortal,
  UiRange,
  UiIcon,
  UiCopyButton
} from '@shelf.network/ui-kit'
import Calendar from 'Common/Calendar'
import { formatDate } from 'Utils/dateHelpers'
import { downloadCsvFile } from 'Utils/downloadCsvFile'
import { showError } from 'Utils/notifications'

const MAX_CODES_TO_SHOW = 10

export default {
  name: 'carfax-promo-codes',
  components: {
    UiButtonV2,
    UiIcon,
    UiCopyButton,
    UiModal,
    UiPortal,
    UiRange,
    Calendar
  },
  data () {
    return {
      codesAmount: 1,
      codesExpirationDate: this.getFutureDate(7),
      isPromoCodesModalShown: false,
      showDownloadMessage: false,
      generated: []
    }
  },
  computed: {
    maxCodesToShow: () => MAX_CODES_TO_SHOW,
    isValidDate () {
      return this.codesExpirationDate.getTime() > Date.now()
    },
    copyCodesList () {
      return this.generated.map(c => c.id).join('\n')
    },
    blobPromoCodes () {
      const tableHeadRows = [
        [
          this.$t('EXPORT_TABLE_HEAD'), ''
        ],
        [
          this.$t('EXPORT_TABLE_GENERATED'),
          formatDate(new Date(), 'yyyy-MM-dd HH:mm')
        ],
        [
          this.$t('EXPORT_TABLE_EXP'),
          formatDate(this.codesExpirationDate, 'yyyy-MM-dd HH:mm')
        ],
        ['', '']
      ].map(row => row.join(','))

      const contentRows = this.generated.map((item, i) => {
        return [
          i + 1,
          item.id,
        ].join(',')
      })

      return [...tableHeadRows, ...contentRows].join('\n')
    }
  },
  methods: {
    exportPromoCodes () {
      try {
        const generated = formatDate(new Date(), 'yyyy-MM-dd_HH-mm')
        const exp = formatDate(this.codesExpirationDate, 'yyyy-MM-dd')
        const filename = `promo-codes_${generated}_exp-${exp}`

        downloadCsvFile(this.blobPromoCodes, filename)
      } catch (e) {
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        console.error(e)
      }
    },
    close () {
      this.resetPromoCodes()
      this.codesAmount = 1
      this.codesExpirationDate = this.getFutureDate(7)
      this.isPromoCodesModalShown = false
    },
    resetPromoCodes () {
      this.generated.splice(0)
      this.showDownloadMessage = false
    },
    setDefaultExpDate (days = 7) {
      this.codesExpirationDate = this.getFutureDate(days)
    },
    getFutureDate (days = 7) {
      const date = new Date()
      date.setDate(date.getDate() + days)
      date.setHours(0, 0, 0, 0)
      return date
    },
    async generatePromoCodes () {
      try {
        const { data } = await sdk.carfax.generatePromoCodes(
          Number(this.codesAmount), this.codesExpirationDate
        )

        this.generated.push(...data)

        if (this.codesAmount > MAX_CODES_TO_SHOW) {
          this.exportPromoCodes()
          this.showDownloadMessage = true
        }
      } catch (e) {
        console.error(e)
      }
    },
    formatDate,
  }
}
</script>

<style lang="scss" scoped>
.carfax-promo-codes {
  &__btn {
    @include spacing-padding(sp200, sp400);
    @include typo(h200, medium);

    display: flex;
    height: 100%;
  }

  &__heading {
    @include typo(h400, bold);
    @include typo-height(h500);
    @include spacing-padding(sp100, 0, sp400, 0);

    @include respond-below(sm) {
      text-align: center;
    }
  }

  &__content {
    width: 100vw;
    max-width: 550px;
  }

  &__form {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: spacing(sp600);
  }

  &__opt {
    flex-direction: column;
    justify-content: space-between;
    display: flex;

    &-expire {
      @include spacing-padding(sp200, 0);

      margin-top: auto;

      &-lbl {
        @include typo(h200);

        margin-bottom: spacing(sp100);
        color: $color-dark-grey;
      }

      &-date {
        @include typo(h300, medium);

        color: $color-sys-info;
      }
    }

    &-amount {
      &-val {
        @include typo(h600, bold);
        @include spacing-padding(sp500);

        display: block;
        text-align: center;
      }

      &-lbl {
        text-align: left;
      }
    }

    &-calendar {
      &-presets {
        @include spacing-padding(sp200, 0);

        text-align: center;
      }

      &-preset {
        @include button-type(light);
        @include typo(h200);
        @include spacing-padding(sp100, sp200);

        display: inline-block;
        max-width: min-content;
        white-space: nowrap;
      }
    }
  }

  &__action {
    @include spacing-padding(sp100, 0);
    @include spacing-margin(sp300, 0);

    &-group {
      display: grid;
      grid-gap: spacing(sp300);
      grid-template-columns: 1fr 1fr;
      margin-top: spacing(sp300);
    }

    &-download {
      @include button-type(secondary);

      &-icon {
        @include icon-size(h300);

        margin-right: spacing(sp200);
      }
    }

    &-copy {
      display: block;

      & /deep/ .ui-button__button {
        @include spacing-padding(sp300);

        margin: 0;
        display: block;
        background: color-code(pal-grey200);
        border-radius: var(--ui-layout--border-radius);

        & /deep/ .ui-copy-button__icon {
          color: $color-sys-info;
        }

        &::before,
        &::after {
          z-index: $layout-z-local-hight;
        }
      }
    }

    &-btn {
      @include button-type(secondary);

      &_reset {
        @include button-type(dark);
      }
    }
  }

  &__downloaded-msg {
    @include typo(h300, medium);
    @include spacing-padding(sp500, sp200);

    text-align: center;
  }

  &__list {
    @include spacing-margin(0, 0, sp400, 0);

    display: flex;
    flex-direction: column;

    &-item {
      display: block;
    }

    &-item /deep/ .ui-button__button {
      border: 1px solid transparent;

      @include spacing-margin(sp100, 0);
      @include spacing-padding(sp200, sp300);
      @include typo(h300, medium);

      &::before,
      &::after {
        z-index: $layout-z-local-hight;
      }

      &:hover {
        background: $color-light-grey;
      }

      &:active,
      &:focus {
        border: 1px solid $color-sys-success;
      }
    }
  }

  @include respond-below(sm) {
    &__content {
      width: unset;
    }

    &__form {
      display: block;
      text-align: center;
    }

    &__opt {
      &:last-child {
        margin-left: 0;
        display: block;
      }

      &-amount {
        @include spacing-padding(0, sp500);

        &-lbl {
          @include spacing-padding(sp200, 0, sp400, 0);

          text-align: center;
        }

        &-val {
          @include spacing-padding(sp300);
          @include typo(h400, bold);
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "AMOUNT_LBL": "Amount of reports to generate",
    "EXPIRED_LBL": "Promo-codes will be expired at:",
    "PROMO_CODES": "Promo-Codes",
    "GENERATE_BTN": "Generate",
    "GENERATE_AGAIN_BTN": "Generate Again",
    "COPY_ALL_BTN": "Copy All Codes",
    "DEFAULT_EXP_7_DAYS": "7 days",
    "DEFAULT_EXP_14_DAYS": "2 weeks",
    "DEFAULT_EXP_30_DAYS": "1 month",
    "EXPORT_TABLE_HEAD": "Carfax Promo-Codes",
    "EXPORT_TABLE_GENERATED": "Generated:",
    "EXPORT_TABLE_EXP": "Valid until:",
    "EXPORT_CSV_BTN": "Export CSV",
    "PROMO_CODES_DOWNLOADED_MSG" : "Promo-codes were downloaded as CSV-file."
  },
  "ka": {
    "AMOUNT_LBL": "რეპორტების რაოდენობა",
    "EXPIRED_LBL": "პრომო კოდს ვადა გაუვა:",
    "PROMO_CODES": "პრომო კოდები",
    "GENERATE_BTN": "გენერაცია",
    "GENERATE_AGAIN_BTN": "დააგენენირე კიდევ",
    "COPY_ALL_BTN": "ყველა კოდის კოპირება",
    "DEFAULT_EXP_7_DAYS": "7 დღე",
    "DEFAULT_EXP_14_DAYS": "2 კვირა",
    "DEFAULT_EXP_30_DAYS": "1 თვე",
    "EXPORT_TABLE_HEAD": "პრომო კოდები",
    "EXPORT_TABLE_GENERATED": "შექმნილია:",
    "EXPORT_TABLE_EXP": "ძალაშია:",
    "EXPORT_CSV_BTN": "ლიდების CSV",
    "PROMO_CODES_DOWNLOADED_MSG" : "პრომო კოდები ჩამოწერილია CSV ფაილად."
  },
  "ru": {
    "AMOUNT_LBL": "Количество промо-кодов",
    "EXPIRED_LBL": "Промо-коды будут действовать до:",
    "PROMO_CODES": "Промо-коды",
    "GENERATE_BTN": "Сгенерировать",
    "GENERATE_AGAIN_BTN": "Сгенерировать снова",
    "COPY_ALL_BTN": "Копировать весь список",
    "DEFAULT_EXP_7_DAYS": "7 дней",
    "DEFAULT_EXP_14_DAYS": "2 недели",
    "DEFAULT_EXP_30_DAYS": "1 месяца",
    "EXPORT_TABLE_HEAD": "Промо-коды для Carfax",
    "EXPORT_TABLE_GENERATED": "Создано:",
    "EXPORT_TABLE_EXP": "Действительны до:",
    "EXPORT_CSV_BTN": "Экспорт CSV",
    "PROMO_CODES_DOWNLOADED_MSG" : "Промо-коды сохранены в CSV-файл."
  },
  "uk": {
    "AMOUNT_LBL": "Кількість промо-кодів",
    "EXPIRED_LBL": "Промо-коди діятимуть до:",
    "PROMO_CODES": "Промо-коди",
    "GENERATE_BTN": "Згенерувати",
    "GENERATE_AGAIN_BTN": "Згенерувати знову",
    "COPY_ALL_BTN": "Скопіювати увесь список",
    "DEFAULT_EXP_7_DAYS": "7 днів",
    "DEFAULT_EXP_14_DAYS": "2 тижні",
    "DEFAULT_EXP_30_DAYS": "1 місяць",
    "EXPORT_TABLE_HEAD": "Промо-коди для Carfax",
    "EXPORT_TABLE_GENERATED": "Створено:",
    "EXPORT_TABLE_EXP": "Дійсні до:",
    "EXPORT_CSV_BTN": "Експортувати CSV",
    "PROMO_CODES_DOWNLOADED_MSG": "Промо-коди збережено як CSV-файл."
  }
}
</i18n>
