<template>
  <div class="carfax-item">
    <div class="carfax-item__name">
      <ui-icon
        class="carfax-item__icon carfax-item__icon_name"
        icon="no-car"
      />

      <span>{{ report.name }}</span>
    </div>

    <div class="carfax-item__vin">
      <span class="carfax-item__icon carfax-item__icon_vin">
        {{ $t('VIN_LBL') }}
      </span>

      <span>{{ report.vin }}</span>
    </div>

    <div class="carfax-item__email">
      <ui-icon
        class="carfax-item__icon"
        icon="message"
      />

      <a
        v-if="report.email"
        class="carfax-item__link"
        :href="`mailto:${report.email}`"
      >
        <span>
          {{ report.email }}
        </span>
      </a>

      <span v-else>
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </span>
    </div>

    <div class="carfax-item__phone">
      <ui-icon
        class="carfax-item__icon"
        icon="call"
      />

      <a
        v-if="report.phone"
        class="carfax-item__link"
        :href="`tel:${report.phone}`"
      >
        <span>
          {{ formattedPhone }}
        </span>
      </a>

      <span v-else>
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </span>
    </div>

    <div class="carfax-item__time">
      <ui-icon
        class="carfax-item__icon"
        icon="timer-alt"
      />

      <span>{{ formattedTime }}</span>
    </div>

    <div class="carfax-item__report">
      <a
        class="carfax-item__report-link"
        :href="report.link"
        target="_blank"
      >
        <ui-button
          class="carfax-item__report-link-btn"
          fill="none"
          look="waiting"
        >
          <ui-icon
            class="carfax-item__icon"
            icon="link"
          />
          {{ $t('REPORT_LINK') }}
        </ui-button>
      </a>
    </div>
  </div>
</template>

<script>
import { UiIcon, UiButton, formatPhone } from '@shelf.network/ui-kit'
import { CarfaxReport } from 'Models/CarfaxReport'
import { formatTime } from 'Utils/dateHelpers'

export default {
  name: 'carfax-item',
  components: {
    UiIcon,
    UiButton
  },

  props: {
    report: {
      type: CarfaxReport,
      required: true
    }
  },

  computed: {
    formattedTime () {
      return formatTime(this.report.fulfilledAt)
    },

    formattedPhone () {
      return formatPhone(this.report.phone)
    }
  }
}
</script>

<style lang="scss" scoped>
.carfax-item {
  position: relative;
  display: grid;
  transition: all 150ms;
  padding: 1.8em 0;
  border-radius: 15px;
  align-items: center;
  grid-template-columns: 25% 15% 20% 15% 7.5% 7.5%;
  grid-gap: 2%;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-bottom: 1px solid $color-grey;
    transition: all 0ms;
  }

  &:not(:first-child) {
    margin-top: -1.5px; // cover bottom border of the previous element
  }

  @include respond-above(sm) {
    padding: 1.8em 1em;

    &::after {
      left: 1em;
      right: 1em;
    }

    &:hover {
      background: $color-ui-bg;
      box-shadow: 0 4px 20px rgba($color-black, 0.1);

      &::after {
        opacity: 0;
      }
    }
  }

  &__icon {
    font-size: 1.2em;
    margin-right: 0.4em;

    &_name {
      padding: 0.6em;
      font-size: 1.4em;
      background-color: $color-light-grey;
      color: $color-ui-secondary;
      border-radius: 5px;
      margin-right: 0.8em;
    }

    &_vin {
      border-radius: 3px;
      background-color: $color-darker-grey;
      color: $color-white;
      font-size: 0.7em;
      margin-right: 0.9em;
      padding: 0.4em 0.4em 0.2em;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    line-height: 1.6em;
  }

  &__link {
    text-decoration: none;
    color: $color-darker-grey;
  }

  &__vin,
  &__email,
  &__phone,
  &__time,
  &__report {
    display: flex;
    align-items: center;
    color: $color-darker-grey;
  }

  &__report {
    &-link {
      width: fit-content;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DAYS_TXT": " Days",
    "HOURS_TXT": " Hours",
    "MINUTES_TXT": " Minutes",
    "VIN_LBL": "VIN",
    "REPORT_LINK": "Report"
  },
  "ka": {
    "DAYS_TXT": " დღე",
    "HOURS_TXT": " საათი",
    "MINUTES_TXT": " წუთი",
    "VIN_LBL": "ვინკოდი",
    "REPORT_LINK": "რეპორტი"
  },
  "ru": {
    "DAYS_TXT": "д",
    "HOURS_TXT": "ч",
    "MINUTES_TXT": "м",
    "VIN_LBL": "VIN",
    "REPORT_LINK": "Отчет"
  },
  "uk": {
    "DAYS_TXT": "д",
    "HOURS_TXT": "ч",
    "MINUTES_TXT": "м",
    "VIN_LBL": "VIN",
    "REPORT_LINK": "Звіт"
  }
}
</i18n>
