<template>
  <div class="list">
    <template v-if="listLength === 0">
      <template v-if="isLoading">
        <div
          class="list__items-wrp"
          :class="{ 'list__items-wrp_grid': isGridTemplate }"
        >
          <slot name="loading-stub" />
        </div>
      </template>

      <template v-else>
        <slot name="no-list-msg-pre" />

        <div class="list__no-list-stub">
          <slot name="no-list-msg" />
        </div>
      </template>
    </template>

    <template v-else>
      <div
        class="list__items-wrp"
        :class="{
          'list__items-wrp_loading': isLoading,
          'list__items-wrp_grid': isGridTemplate,
        }"
      >
        <slot name="list" />

        <template v-if="!isNoMoreItems && isLoadingNextSkeletonEnabled">
          <slot name="next-loading-stub" />
        </template>
      </div>
    </template>

    <div
      class="list__infinite-scroll-anchor"
      ref="infiniteScrollAnchor"
    />
  </div>
</template>

<script>
import throttle from 'lodash/throttle'

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isNextLoading: {
      type: Boolean,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: [Number, String],
      default: 0,
    },
    loadMore: {
      type: Function,
      required: true,
    },
    isGridTemplate: {
      type: Boolean,
      default: false,
    },
    /**
     * Handles specific edge case, when we're switching from tab with:
     *
     * listLength = 10
     * pageSize = 10
     * isNoMoreItems = true (10 elements in whole collection, all loaded)
     *
     * to the tab with same values:
     *
     * listLength = 10
     * pageSize = 10
     *
     * where we still don't know about the total amount of elements in
     * collection - are required to do `checkIsMoreItems` once again.
     *
     * In such case resetOnChange, if bound to the `$route.params.tab`
     * or `template` properties of parent component, triggers re-check
     * when being changed.
     */
    resetOnChange: {
      type: String,
      default: '',
    },
  },

  data: _ => ({
    isLoadingNextSkeletonEnabled: true,
    isNoMoreItems: false,
    observer: null,
  }),

  computed: {
    isInfiniteScrollDisabled () {
      return this.isLoading ||
        this.isNextLoading ||
        this.isNoMoreItems ||
        this.listLength === 0
    },
  },

  created () {
    const loadMoreThrottled = throttle(() => this.loadMoreWrp(), 300)

    // Load more when the "infiniteScrollAnchor" is in the viewport
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        loadMoreThrottled()
      }
    })

    this.$watch(
      _ => this.resetOnChange,
      _ => { this.isNoMoreItems = this.listLength < this.pageSize },
      { immediate: true },
    )

    this.$watch(
      _ => this.listLength,
      (newLength, oldLength = 0) => {
        this.isNoMoreItems = newLength < oldLength || oldLength < this.pageSize
          ? this.isNoMoreItems && newLength < this.pageSize
          : (newLength - oldLength) < this.pageSize
        this.forceRerenderNextLoadingStub()
      },
      { immediate: false }
    )

    // Re-subscribe to observer to handle the case when the anchor
    // remains in viewport in process of fetching next page.
    this.$watch(
      'isInfiniteScrollDisabled',
      isDisabled => isDisabled
        ? this.observer.unobserve(this.$refs.infiniteScrollAnchor)
        : this.observer.observe(this.$refs.infiniteScrollAnchor)
    )
  },

  destroyed () {
    this.observer.disconnect()
  },

  methods: {
    async loadMoreWrp () {
      if (this.isLoading || this.isNextLoading) return

      const oldLength = this.listLength
      await this.loadMore()

      if (this.listLength - oldLength === 0) {
        this.isNoMoreItems = true
      }
    },

    forceRerenderNextLoadingStub () {
      // Force re-render "load more" skeleton to avoid getting stuck when
      // it takes all viewport height
      this.isLoadingNextSkeletonEnabled = false

      setTimeout(() => {
        this.isLoadingNextSkeletonEnabled = true
      }, 60)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;

  &__items-wrp {
    display: grid;
    grid-auto-flow: row;
    gap: 2.5em;

    &_loading {
      @include loading-fog();
    }

    &_grid {
      @include respond(
        grid-template-columns,
        repeat(auto-fill, minmax(250px, 1fr)),
        repeat(auto-fill, minmax(220px, 1fr)),
        repeat(auto-fill, minmax(220px, 1fr)),
        repeat(auto-fill, minmax(300px, 1fr))
      );
      @include respond(grid-gap, 25px, 15px, 10px, 15px);

      display: grid;
      grid-template-rows: auto;
      grid-auto-columns: max-content;
    }
  }

  &__no-list-stub {
    @extend %p;

    margin: 4em 0;
    text-align: center;
    color: $color-ui-secondary;
    font-size: 1.8em;
  }

  &__infinite-scroll-anchor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150vh; // Always keep at least one screen of content ahead
    max-height: 33%; // Handle lists embedded in dropdowns and etc
    z-index: z-index(negative);
    pointer-events: none;

    @include respond-below(sm) {
      height: 210vh;
    }
  }
}
</style>
