<template>
  <div class="carfax">
    <page-subnav class="carfax__subnav">
      <carfax-promo-codes class="carfax__subnav-promo-codes" />

      <template #right>
        <search-form
          class="carfax__subnav-search"
          @submit="loadReports"
        />
      </template>
    </page-subnav>

    <list
      class="carfax__list-wrp"
      :is-loading="isLoading"
      :is-next-loading="isNextReportsLoading"
      :list-length="reports.length"
      :page-size="CARFAX_PAGE_LIMIT"
      :load-more="loadMore"
    >
      <template slot="loading-stub">
        <carfax-list-head class="carfax__list-head" />

        <div class="carfax__list-skeleton">
          <carfax-list-skeleton
            v-for="i in 3"
            :key="i"
          />
        </div>
      </template>

      <template slot="no-list-msg">
        {{ $t('NO_REPORTS_MSG') }}
      </template>

      <template slot="list">
        <carfax-list
          v-if="reports.length"
          class="carfax__list"
          :reports="reports"
        />
      </template>

      <template slot="next-loading-stub">
        <carfax-item-skeleton />
      </template>
    </list>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import List from 'Common/List'

import SearchForm from './components/SearchForm'
import CarfaxList from './components/CarfaxList'
import CarfaxPromoCodes from './components/CarfaxPromoCodes'
import CarfaxListHead from './components/CarfaxListHead'

import CarfaxListSkeleton from './components/CarfaxListSkeleton'
import CarfaxItemSkeleton from './components/CarfaxItemSkeleton'

import { mapActions, mapGetters } from 'vuex'
import { actions } from './store/types'
import { CARFAX_PAGE_LIMIT } from './store/constants'

export default {
  name: 'carfax',
  components: {
    PageSubnav,
    List,

    SearchForm,
    CarfaxPromoCodes,
    CarfaxList,
    CarfaxListHead,

    CarfaxItemSkeleton,
    CarfaxListSkeleton
  },

  data () {
    return {
      CARFAX_PAGE_LIMIT
    }
  },

  computed: {
    ...mapGetters('ui/carfax', {
      reports: 'reports',
      isLoading: 'isLoading',
      isNextReportsLoading: 'isNextReportsLoading'
    })
  },

  async created () {
    await this.loadReports()
  },

  methods: {
    ...mapActions('ui/carfax', {
      loadReports: actions.LOAD_REPORTS,
      loadMore: actions.LOAD_MORE_REPORTS
    }),
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`
    }
  }
}
</script>

<i18n>
{
  "en": {
    "NO_REPORTS_MSG": "No Carfax reports",
    "META_TITLE": "Carfax Reports"
  },
  "ka": {
    "NO_REPORTS_MSG": "თქვენ არ გაქვთ შეძენილი რეპორტი",
    "META_TITLE": "Carfax რეპორტები"
  },
  "ru": {
    "NO_REPORTS_MSG": "Нет отчетов Carfax",
    "META_TITLE": "Отчеты Carfax"
  },
  "uk": {
    "NO_REPORTS_MSG": "Нема звітів Carfax",
    "META_TITLE": "Звіти Carfax"
  }
}
</i18n>

<style lang="scss" scoped>
.carfax {
  &__list-wrp {
    padding: 2em 3em 0;
  }

  &__list-head,
  &__list {
    min-width: 1600px;
    overflow-x: auto;
  }
}
</style>
