<template>
  <form
    class="search-form"
    novalidate
    @submit.prevent="submitDebounced.cancel() || submit()"
  >
    <ui-text
      ref="searchInput"
      class="search-form__input"
      name="carfax-search"
      v-model.trim="form.search"
      :maxlength="VIN_LENGTH"
      :placeholder="$t('SEARCH_PH')"
      @input="onSearchInput"
    >
      <button
        type="submit"
        class="search-form__submit-btn"
        @click="submit"
        slot="dock-left"
      >
        <ui-icon
          class="search-form__submit-btn-icon"
          icon="search"
        />
      </button>
    </ui-text>
  </form>
</template>

<script>
import { UiIcon, UiText } from '@shelf.network/ui-kit'
import debounce from 'lodash/debounce'
import { VIN_LENGTH } from '../store/constants'

export default {
  name: 'search-form',
  components: {
    UiIcon,
    UiText
  },

  data () {
    return {
      form: {
        search: '',
      },
      submitDebounced: null, // populated on created
      VIN_LENGTH
    }
  },

  created () {
    this.submitDebounced = debounce(function () {
      this.submit()
    }, 1000)
  },

  methods: {
    onSearchInput (value) {
      this.submitDebounced()
    },

    submit () {
      this.$emit('submit', this.form.search)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  &__input {
    width: 20em;

    @include respond-below(sm) {
      width: auto;
    }

    & /deep/ .ui-text {
      &__input {
        border-radius: 1em;
        padding: 0 0 0 2.4em;
        width: 100%;

        &:not(:focus) {
          border-color: $color-ui-secondary;
        }
      }

      &__dock {
        display: flex;
        justify-content: flex-end;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__submit-btn {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: $color-ui-secondary;
    padding: 0.25em;
    transition: visibility 0.15s, opacity 0.15s;
    font-size: 1.2em;
  }
}
</style>

<i18n>
{
  "en": {
    "SEARCH_PH": "Type to search"
  },
  "ka": {
    "SEARCH_PH": "ძიება"
  },
  "ru": {
    "SEARCH_PH": "Поиск"
  },
  "uk": {
    "SEARCH_PH": "Пошук"
  }
}
</i18n>
